import React, { memo } from 'react'
import Layout from 'components/Layout/Layout'
import Banner from 'components/Banner/Banner'
import CustomDevelopment from 'components/CustomDevelopment/CustomDevelopment'
import styled from 'styled-components'
import CaseStudySlider from 'components/CaseStudySlider/CaseStudySlider'
import BuildApplication from 'components/BuildApplication/BuildApplication'
import SEO from 'components/Seo'
import { MobileAppSerProps } from 'components/Props/mobile-apps'
import { md } from 'config/variables'
import { graphql } from 'gatsby'
import { images } from 'config/images'

import CustomerReviewSlider from 'components/CustomerReviewSlider/CustomerReviewSlider'
import DevelopSoftAgile from 'components/DevelopSoftAgile/DevelopSoftAgile'
import IndustriesServe from 'components/IndustriesServe/IndustriesServe'
import Techstacks from 'components/Techstacks/Techstacks'
import FaqSection from 'components/FaqSection/FaqSection'
import FeatureResources from 'components/FeatureResources/FeatureResources'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import ProcessFlow from 'components/ProcessFlow/ProcessFlow'
import Serviceofferingssection from 'components/Serviceofferingssection/Serviceofferingssection'
import RangeServiceListSec from 'components/RangeServiceListSec/RangeServiceListSec'
import Aboutprojectform from 'components/Aboutprojectform/Aboutprojectform'
import SimformGuaranteeSec from 'components/SimformGuaranteeSec/SimformGuaranteeSec'

export const MobileAppSerPage = styled.div`
  .banner {
    .banner-content {
      max-width: 900px;
      p {
        max-width: 830px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
    }
    &:after {
      /* background-image: linear-gradient(to top, #ffffff, #fff0e7); */
    }
  }
  .buildapp-section {
    .heading-para {
      max-width: 750px;
    }
  }
  .feature-resource-section {
    background-image: linear-gradient(to top, #eef2ff, #fff);
    .contact-info-card {
      display: none;
    }
  }
  .developsoft-section {
    ${md(`
      display: none;
    `)}
  }
  .industries-service {
    ${md(`
      display: none;
    `)}
  }
  .getintouch-section {
    .awards-recognition-blk {
      ${md(`
        display: none;
      `)}
    }
  }
`

const MobileAppSer = memo(props => {
  const { data } = props
  const { location } = props
  // const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent: true })

  let processFlowItem = [
    props.data.process1.childImageSharp.fluid,
    props.data.process2.childImageSharp.fluid,
    props.data.process3.childImageSharp.fluid,
    props.data.process4.childImageSharp.fluid,
    props.data.process5.childImageSharp.fluid,
  ]
  let posterImage = [props.data.posterThumbImage.childImageSharp.fluid]
  let offerings = [
    props.data.offeringsWebapp.childImageSharp.fluid,
    props.data.offeringsMobileApp.childImageSharp.fluid,
    props.data.offeringsOtherApp.childImageSharp.fluid,
  ]
  return (
    <Layout
      mainClass="enterprise-mob-dev services-page"
      location={location}
      data={{
        img: images.contactHs,
        img2x: images.contactHs2x,
        name: 'Hardik Shah',
        description:
          'Creating a tech product roadmap and building scalable apps for your organization.',
      }}
    >
      <SEO
        title="Mobile App Development Services for Android & iOS"
        description="Simform offers award-winning mobile app development services tailored to your business needs. We develop innovative mobile apps for iOS & Android. Get in touch for a free consultation."
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/services/mobile-app-development/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/services/mobile-app-development/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <MobileAppSerPage>
        <Banner
          bannerData={MobileAppSerProps}
          showBannerImage={true}
          {...props}
        />
        <div className="lazy-load-div">
          <BuildApplication buildApplicationData={MobileAppSerProps} />
          <CustomDevelopment
            customServicedata={MobileAppSerProps.customDevProps}
          />
        </div>
        <Serviceofferingssection
          ServiceofferingsData={MobileAppSerProps.serviceofferingsProps}
          items={offerings}
        />
        <RangeServiceListSec
          rangeServiceListData={MobileAppSerProps.rangeServiceListDataProps}
        />
        <ProcessFlow
          ProcessFlowData={MobileAppSerProps.workingBenefitsProps}
          items={processFlowItem}
        />
        <CaseStudySlider caseStudydata={MobileAppSerProps} {...props} />
        <CustomerReviewSlider
          customReviewSliderdata={MobileAppSerProps}
          {...props}
        />
        <Aboutprojectform
          AboutprojectformData={MobileAppSerProps.AboutprojectformProps}
        />
        <DevelopSoftAgile developSoftAgiledata={MobileAppSerProps} />
        <IndustriesServe industriesServedata={MobileAppSerProps} />
        <Techstacks techstacksdata={MobileAppSerProps} />
        <SimformGuaranteeSec
          SimformGuaranteeProps={MobileAppSerProps.SimformGuaranteeData}
          posterImage={posterImage}
        />
        <FaqSection faqSectiondata={MobileAppSerProps} />
        <FeatureResources featureresourceData={MobileAppSerProps} {...props} />
      </MobileAppSerPage>
    </Layout>
  )
})

export default MobileAppSer

export const query = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/mobile-app-banner@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1113) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case1: file(relativePath: { regex: "/case-onlymob@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case2: file(relativePath: { regex: "/case-safari@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case3: file(relativePath: { regex: "/case-ipad@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case4: file(relativePath: { regex: "/case-mobile@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case5: file(relativePath: { regex: "/redbull-case@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication1: file(
      relativePath: { regex: "/blog-app-performance@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication2: file(
      relativePath: { regex: "/blog-react-native-vs-ionic@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication3: file(
      relativePath: { regex: "/blog-flutter-vs-ionic@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    servicebenefit: file(
      relativePath: { regex: "/enterprise-benefit-banner@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 749) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    olivia: file(relativePath: { regex: "/olivia.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    justin: file(relativePath: { regex: "/justin.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ritwik: file(relativePath: { regex: "/ritwik.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    danielle: file(relativePath: { regex: "/danielle.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jawann: file(relativePath: { regex: "/jawann.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jansen: file(relativePath: { regex: "/jansen.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jeff: file(relativePath: { regex: "/Jeff.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    andy: file(relativePath: { regex: "/andy.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process1: file(relativePath: { regex: "/ill-powerenterprise@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process2: file(relativePath: { regex: "/ill-helpedautomate@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process3: file(relativePath: { regex: "/ill-deliveryroadmap@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process4: file(relativePath: { regex: "/ill-retentionrates@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process5: file(relativePath: { regex: "/ill-secuirityrisks@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    posterThumbImage: file(relativePath: { regex: "/poster-img.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    offeringsWebapp: file(
      relativePath: { regex: "/offerings-ios-app-thumb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 524) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    offeringsMobileApp: file(
      relativePath: { regex: "/offerings-android-app-thumb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 524) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    offeringsOtherApp: file(
      relativePath: { regex: "/offerings-shybrid-app-thumb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 524) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
